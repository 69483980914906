import React, { useEffect, useState } from "react";
import { SEO } from "../components";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import ThirdSection from "../components/layout/third-section";
import MailSection from "../components/aboutus/about-us-mail-section";
import RealEstateSection from "../components/layout/real-estate-section";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";

const Listing = ({ data }) => {
  const seo = data.sanitySeo;
  const banner = data.sanityBanners.banner.asset.url;
  const propertiesData = data.allSanityProperty.nodes;
  const mendocinoestate = data.sanityMendocinoestate;
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  let [properties, setProperties] = useState([]);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();

  let size = 3;
  useEffect(() => {
    let arrayOfArrays = [];
    for (var i = 0; i < propertiesData.length; i += size) {
      arrayOfArrays.push(propertiesData.slice(i, i + size));
    }
    setProperties(arrayOfArrays);
  }, []);

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            title="Listings"
            bread="LISTINGS"
            buttontext=""
            position="50%"
            src={banner}
            cover="cover"
          />
          <div
            style={{
              backgroundColor: "#f7f7f7",
              marginTop: "30px",
            }}
          >
            {properties.map((array, index) => (
              <ThirdSection key={index} properties={array} />
            ))}
          </div>
          <div ref={ref}>
            <MailSection />
            <div
              style={{
                backgroundColor: "#f7f7f7",
              }}
            >
              <RealEstateSection mendocinoestate={mendocinoestate} />
            </div>
            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default Listing;

export const query = graphql`
  {
    sanityBanners(id: { eq: "-e59822d0-a4bc-5505-81ad-b5020a3b9cf4" }) {
      id
      pageTitle
      banner {
        asset {
          url
        }
      }
    }
    allSanityProperty(
      filter: { offMarket: { eq: false } }
      sort: { order: ASC, fields: propertystatus }
    ) {
      nodes {
        propertyname
        slug {
          current
        }
        propertyprice
        propertystatus
        propertyimage {
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: WEBP
              placeholder: BLURRED
            )
          }
        }
      }
    }
    sanityMendocinoestate(id: { eq: "-3a77c8f4-0436-594a-bbe4-6c88e2623615" }) {
      id
      title
      description
      image {
        asset {
          gatsbyImageData
        }
      }
    }
    sanitySeo(pagehandle: { eq: "listing" }) {
      title
      description
      keywords
      ldSchema
    }
  }
`;
