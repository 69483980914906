import React from "react";
import { Card, CardText, CardImgOverlay } from "reactstrap";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const ThiredSection = ({ properties }) => {
  return (
    <div
      className="container-fluid"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        marginTop: "-8px",
      }}
    >
      <div className="row" style={{ border: "0" }}>
        {/* 1st card */}
        {/* <Slider {...settings}> */}
        {properties?.map((property, index) => (
          <div
            key={index}
            className="col-sm-12 col-md-6 col-lg-4 thirdSection-card"
            style={{
              width: "33.33%",
              // height: "572px",
              padding: "0",
              margin: "0",
              boxSizing: "border-box",
            }}
          >
            <Card
              style={{
                height: "572px",
                border: "1px solid #076332",
                boxSizing: "inherit",
                borderRadius: "0px",
              }}
              className="thirdSection-card-sub"
              inverse
            >
              <GatsbyImage
                style={{
                  border: "0",
                  width: "100%",
                  height: "100%",
                  padding: "0px",
                  margin: "0px",
                  maxWidth: "1000px",
                  // maxHeight: "600px",
                }}
                image={property.propertyimage.asset.gatsbyImageData}
                alt={property.propertyname}
                loading="lazy"
                objectFit="cover"
                objectPosition="center"
              />
              <Link
                to={`/property/${property.slug.current}`}
                style={{ border: "0" }}
              >
                <CardImgOverlay
                  className="third-section-card"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    border: "0",
                    borderRadius: "0px !important",
                  }}
                >
                  <CardText className="third-section-active">
                    {property.propertystatus}
                  </CardText>
                  <CardText style={{ fontSize: "21px", fontWeight: "600" }}>
                    <Link to={`/property/${property.slug.current}`}>
                      {property.propertyname}
                    </Link>
                  </CardText>
                  <CardText style={{ fontSize: "21px", fontWeight: "600" }}>
                    $
                    {property.propertyprice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </CardText>
                </CardImgOverlay>
              </Link>
            </Card>
          </div>
        ))}
        {/* </Slider> */}
      </div>
    </div>
  );
};

export default ThiredSection;
