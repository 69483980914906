import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import useWindowDimensions from "../hooks/useDimensions";
// import image from "../../images/real-state-img.jpeg";
const RealEstateSection = ({ mendocinoestate }) => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        minHeight: "405px",
        backgroundColor: "#f7f7f7",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      className="container-sm RealEstateSection-mian"
    >
      <div className="row m-2">
        <div className="col-lg-8 col-sm-12 col-md-7">
          <h3 style={{ fontSize: width > 760 ? "32px" : "25px" }}>
            {mendocinoestate?.title}
          </h3>
          <p style={{ color: "gray", whiteSpace: "pre-wrap" }}>
            {mendocinoestate?.description}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12 col-md-4">
          <GatsbyImage
            className="w-100"
            style={{
              // height: "auto",
              maxWidth: "400px",
              // maxHeight: "400px",
              // verticalAlign: "top",
            }}
            image={mendocinoestate?.image.asset.gatsbyImageData}
            alt={mendocinoestate?.title}
            loading="eager"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      </div>
    </div>
  );
};

export default RealEstateSection;
